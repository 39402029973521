import { createRouter, createWebHistory } from 'vue-router'
//views
const Index = () =>import("@/views/IndexView.vue")
const Theory =()=>import("@/views/TheoryView.vue")
const Virtual =()=>import("@/views/SimulationView.vue")
const Experiment =()=>import("@/views/ExperimentView.vue")
const Analysis =()=>import("@/views/AnalysisView.vue")

//components

const KnowledgePage = ()=>import("@/components/theory/KnowledgePage.vue")
const AnswerPage =()=>import("@/components/theory/AnswerPage.vue")
const DevicePage=()=>import("@/components/theory/DevicePage.vue")
const Report =()=>import("@/components/analysis/ReportPage.vue")
const Iot = ()=>import("@/components/simulation/IotSimulation.vue")
const Network =()=>import("@/components/simulation/NetworkSimulation.vue")
const Evaluation =()=>import("@/components/analysis/EvaluationPage.vue")
const Effect =()=>import("@/components/experiment/EffectPage.vue")
const Guide=()=>import("@/components/experiment/GuidePage.vue")
const routes = [
    {
        path:'/',
        // component:Login,
        component:Index,
        meta:{
            title:'基于虚拟实验平台的物联网传感器综合应用'
        }
    },
    {
    path: '/index',
    component: Index,
        meta:{
        title:'基于虚拟实验平台的物联网传感器综合应用'
        }
    },{
        path:"/theory",
        component:Theory,
        meta:{
            title:'理论考核'
        },
        children:[
            {
                path:"knowledge",
                component:KnowledgePage
            },{
                path:"answer",
                component:AnswerPage
            },{
                path:"device",
                component:DevicePage
            }
        ]
    },{
        path:"/analysis",
        component:Analysis,
        meta:{
            title:'分析评估'
        },
        children:[
            {
                path:"/evaluation",
                component:Evaluation
            },{
                path:"/report",
                component:Report
            }
        ]
    },{
        path:"/simulation",
        component:Virtual,
        meta:{
            title:'仿真实验'
        },
        children:[
            {
                path:"/network",
                component:Network
            },{
                path:"/iot",
                component:Iot
            },{
                path:"/evaluation",
                component:Evaluation
            }
        ]
    },{
        path:"/experiment",
        component:Experiment,
        meta:{
            title:'环境认知'
        },
        children:[
            {
                path:"/effect",
                component:Effect
            },{
                path:"guide",
                component:Guide
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
   
})
router.beforeEach((to,from,next) => {
    document.title = to.meta["title"];
    next();
})
export default router
